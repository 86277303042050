import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import SearchPage from './pages/SearchPage';
import ResultPage from './pages/ResultPage';
import ComingSoon from './components/ComingSoon';
import About from './pages/About';
import ContactUs from './pages/ContactUs';
import Map from './pages/MapPage';

// Wrapper component to handle routing based on query parameters
const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/contactUs" element={<ContactUs />} />
      <Route path="/search" element={<QueryHandler />} />
      <Route path="/searchResult" element={<ComingSoon />} />
      <Route path="/map" element={<Map />} />
    </Routes>
  </Router>
);

// Component to handle query parameters and route accordingly
const QueryHandler = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Check for specific query parameters to determine which component to render
  if (params.has('t') && params.has('d') && params.has('n')) {
    return <ResultPage />;
  } else {
    return <SearchPage />;
  }
};

export default App;

