import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  GoogleMap,
  //useJsApiLoader,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  InfoWindow,
} from "@react-google-maps/api";
import { Search, Maximize2, Minimize2 } from "lucide-react";
import foodlandIcon from "../assets/group-logos/Foodland.png";
// import foodlandIcon from "../assets/foodlandLogo.png";
import userLocationIcon from "../assets/icons/UserLocation.png";
import "react-virtualized/styles.css";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import List from "react-virtualized/dist/commonjs/List";
import "./MapPage.css";
import axios from "axios";

const ShowAddress = ({
  address1,
  address2,
  address3,
  city,
  state,
  postCode,
}) => (
  <div>
    {address1 && <div>{address1}</div>}
    {address2 && <div>{address2}</div>}
    {address3 && <div>{address3}</div>}
    {city && (
      <div>
        {city}, {state} {postCode}
      </div>
    )}
  </div>
);

const renderItem = ({ index, key, style, data }) => {
  const item = data[index];
  const imageSource = item.GroupImageURL
    ? item.GroupImageURL
    : require("../assets/group-logos/IGA.png");

  return (
    <div key={key} className="card" style={style}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img src={imageSource} alt="Retailer Logo" className="image" />
        <div style={{ flex: 1 }}>
          <div>
            <div className="headingText">{item.RetailerName}</div>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <ShowAddress
              address1={item.AddressStreet1}
              address2={item.AddressStreet2}
              address3={item.AddressStreet3}
              city={item.AddressCity}
              state={item.AddressState}
              postCode={item.AddressPostcode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MyList = ({ locationData }) => {
  const rowRenderer = ({ index, key, style }) => {
    return renderItem({ index, key, style, data: locationData });
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          width={width}
          height={height}
          rowCount={locationData.length}
          rowHeight={120} // Adjust based on your item height
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  );
};
const Logo = () => (
  <div className="gmp-logo">
    <img src={foodlandIcon} alt="Company Logo" />
  </div>
);

const HierarchicalMenu = () => {
  const [activeSegment, setActiveSegment] = useState(null);
  const [activeFamily, setActiveFamily] = useState(null);
  const [activeClass, setActiveClass] = useState(null);

  const menuData = {
    segments: ["Segment 1", "Segment 2"],
    families: {
      "Segment 1": ["Family 1", "Family 2"],
      "Segment 2": ["Family 3", "Family 4"],
    },
    classes: {
      "Family 1": ["Class 1", "Class 2"],
      "Family 2": ["Class 3", "Class 4"],
      "Family 3": ["Class 5", "Class 6"],
      "Family 4": ["Class 7", "Class 8"],
    },
    bricks: {
      "Class 1": ["Brick 1", "Brick 2"],
      "Class 2": ["Brick 3", "Brick 4"],
      "Class 3": ["Brick 5", "Brick 6"],
      "Class 4": ["Brick 7", "Brick 8"],
      "Class 5": ["Brick 9", "Brick 10"],
      "Class 6": ["Brick 11", "Brick 12"],
      "Class 7": ["Brick 13", "Brick 14"],
      "Class 8": ["Brick 15", "Brick 16"],
    },
  };

  return (
    <div className="gmp-menu">
      <h3 className="gmp-menu-title">Menu</h3>
      <ul className="gmp-menu-list">
        {menuData.segments.map((segment) => (
          <li key={segment} className="gmp-menu-item">
            <button
              onClick={() => setActiveSegment(segment)}
              className="gmp-menu-button"
            >
              {segment}
            </button>
            {activeSegment === segment && menuData.families[segment] && (
              <ul className="gmp-submenu-list">
                {menuData.families[segment].map((family) => (
                  <li key={family} className="gmp-menu-item">
                    <button
                      onClick={() => setActiveFamily(family)}
                      className="gmp-menu-button"
                    >
                      {family}
                    </button>
                    {activeFamily === family && menuData.classes[family] && (
                      <ul className="gmp-submenu-list">
                        {menuData.classes[family].map((cls) => (
                          <li key={cls} className="gmp-menu-item">
                            <button
                              onClick={() => setActiveClass(cls)}
                              className="gmp-menu-button"
                            >
                              {cls}
                            </button>
                            {activeClass === cls && menuData.bricks[cls] && (
                              <ul className="gmp-submenu-list">
                                {menuData.bricks[cls].map((brick) => (
                                  <li key={brick} className="gmp-menu-item">
                                    <button className="gmp-menu-button">
                                      {brick}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const TabbedSection = () => {
  const [activeTab, setActiveTab] = useState("brand");

  return (
    <div className="gmp-tabbed-section">
      <div className="gmp-tab-buttons">
        <button
          onClick={() => setActiveTab("brand")}
          className={`gmp-tab-button ${
            activeTab === "brand" ? "gmp-tab-button-active" : ""
          }`}
        >
          Brand
        </button>
        <button
          onClick={() => setActiveTab("product")}
          className={`gmp-tab-button ${
            activeTab === "product" ? "gmp-tab-button-active" : ""
          }`}
        >
          Product
        </button>
      </div>
      <div className="gmp-tab-content">
        {activeTab === "brand" ? <p>Brand Content</p> : <p>Product Content</p>}
      </div>
    </div>
  );
};

const libraries = ["places"];

const MapContainer = () => {
  const mapRef = useRef();
  const [mapCenter, setMapCenter] = useState({
    lat: -34.926373,
    lng: 138.608063,
  }); // Default to New York City -34.926373, 138.608063
  const [searchQuery, setSearchQuery] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [searchLocation, setsearchLocation] = useState(null);
  const [error, setError] = useState(null);
  const [isFoodland, setIsFoodland] = useState(false);
  const searchBoxRef = useRef(null);
  const [locationData, setLocationData] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  // const UserLocationIcon = {
  //   url: userLocationIcon,
  // };
  const UserLocationIcon = {
    url: userLocationIcon,
  };

  const blueMarkerIcon = {
    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  };

  const greenMarkerIcon = {
    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
  };

  // Function to get marker icon URL based on retailer name
  const getMarkerIcon = (retailerName) => {
    switch (retailerName) {
      case "Drakes":
        return require("../assets/icons/Black.png");
      case "Metro":
        return require("../assets/icons/DeepGreen.png");
      case "Woolworths":
        return require("../assets/icons/Green.png");
      case "Foodland":
        return require("../assets/icons/Yellow.png");
      case "Ritchies IGA":
        return require("../assets/icons/Red.png");
      default:
        return require("../assets/icons/Blue.png");
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchLocationData = useCallback(async (location) => {
    try {
      const response = await axios.post(
        "https://api.test-theproducthub.co/members/storeSearchMaps",
        {
          Latitude: location.lat,
          Longitude: location.lng,
        }
      );
      setLocationData(response.data);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  });

  const onLoad = useCallback(function callback(map) {
    mapRef.current = map;
    window.google.maps.event.addListener(map, "fullscreenchange", () => {
      setIsFullScreen(map.getDiv().isFullScreen());
    });
  }, []);

  const onUnmount = useCallback(function callback(map) {
    mapRef.current = null;
  }, []);

  // Get the user's current location
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setMapCenter(location);
          setUserLocation(location);
          await fetchLocationData(location);
        },
        (err) => {
          setError("Error getting location: " + err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
  }, []);

  const handlePlacesChanged = () => {
    if (searchBoxRef.current) {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        const newLocation = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setMapCenter(newLocation);
        setsearchLocation(newLocation);
        setSearchQuery(place.formatted_address);
        //setIsFoodland(place.name.toLowerCase().includes("foodland"));
        setError(null); // Clear any previous errors
      } else {
        setError("Please enter an address.");
      }
    }
  };

  const handleIdle = async () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      const location = {
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      };
      setMapCenter(location);
      await fetchLocationData(location);
    }
  };

  // const handleIdle = useCallback(() => {
  //   const debounceDelay = 1000; // Adjust delay as needed
  //   let timeout;

  //   return () => {
  //     clearTimeout(timeout);
  //     timeout = setTimeout(async () => {
  //       if (mapRef.current) {
  //         const newCenter = mapRef.current.getCenter();
  //         const location = {
  //           lat: newCenter.lat(),
  //           lng: newCenter.lng(),
  //         };
  //         setMapCenter(location);
  //         await fetchLocationData(location);
  //       }
  //     }, debounceDelay);
  //   };
  // }, [fetchLocationData]);

  const mapContainerStyle = {
    width: "100%",
    height: "95%",
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (!value) {
      setSearchQuery(null);
    } else {
      setError(null); // Clear error message when the user starts typing
    }
  };

  const handleSearchButtonClick = () => {
    if (!searchQuery) {
      setError("Please enter an address.");
    } else {
      handlePlacesChanged();
    }
  };

  // const iconSettings = {
  //   url: foodlandIcon,
  // };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyAQYd3-VdTsUfjsmYXEN1JIybkpVpPydoE"
      libraries={libraries}
    >
      <div
        className={
          isFullScreen ? "gmp-fullscreen-overlay" : "gmp-map-list-container"
        }
      >
        <div
          className={
            isFullScreen ? "gmp-fullscreen-overlay" : "gmp-map-container"
          }
        >
          <StandaloneSearchBox
            onLoad={(ref) => (searchBoxRef.current = ref)}
            onPlacesChanged={handlePlacesChanged}
          >
            <div
              className={`gmp-search-container ${
                isFullScreen ? "gmp-fullscreen-search" : ""
              }`}
            >
              <input
                type="text"
                value={searchQuery || ""}
                onChange={handleInputChange}
                placeholder="Search location"
                className="gmp-search-input"
              />
              <button
                onClick={handleSearchButtonClick}
                className="gmp-search-button"
              >
                <Search size={20} />
              </button>
              <div className="gmp-error-message">
                {/* {error ? error : "\u00A0"} */}
                {error}
              </div>
            </div>
          </StandaloneSearchBox>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={mapCenter}
            zoom={14}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onIdle={handleIdle}
            options={{ streetViewControl: false, fullscreenControl: false }}
          >
            {userLocation && (
              <Marker position={userLocation} icon={UserLocationIcon} />
            )}
            <Marker position={mapCenter} icon={blueMarkerIcon} />
            {searchLocation && (
              <Marker position={searchLocation} icon={greenMarkerIcon} />
            )}
            {/* <Marker position={mapCenter} /> */}

            {locationData.map((item) => (
              <Marker
                key={item.StoreID}
                position={{
                  lat: parseFloat(item.Latitude),
                  lng: parseFloat(item.Longitude),
                }}
                icon={getMarkerIcon(item.RetailerName)}
                onClick={() => setSelectedPlace(item)}
              />
            ))}
            {selectedPlace && (
              <InfoWindow
                position={{
                  lat: parseFloat(selectedPlace.Latitude),
                  lng: parseFloat(selectedPlace.Longitude),
                }}
                onCloseClick={() => setSelectedPlace(null)}
              >
                <div>
                  <h2>{selectedPlace.RetailerName}</h2>
                  <p>{selectedPlace.StoreType}</p>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
          <button
            onClick={toggleFullScreen}
            className="gmp-custom-fullscreen-button"
          >
            {isFullScreen ? <Minimize2 size={18} /> : <Maximize2 size={18} />}
          </button>
          <a
            href="https://members.theproducthub.co"
            target="_blank"
            rel="noopener noreferrer"
            className="gmp-producthub-link"
          >
            Powered by ProductHub
          </a>
        </div>
        <div className="list-container">
          <MyList locationData={locationData} />
        </div>
      </div>
    </LoadScript>
  );
};

const MapPage = () => (
  <div>
    <div className="gmp-container">
      <div className="gmp-sidebar">
        <Logo />
        <HierarchicalMenu />
        <TabbedSection />
      </div>
      <MapContainer />
    </div>
  </div>
);

export default MapPage;
